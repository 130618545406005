// Modules
import React from "react";
import styled from "styled-components";

// Styles
interface IProps {
  isValid: boolean;
  validate: boolean;
}
const Text = styled.input<IProps>`
  background-color: ${(props) => {
    if (!props.validate) return props.theme.backgroundInput;
    if (!props.isValid) return props.theme.backgroundInvalid;
    return props.theme.backgroundValid;
  }};
  border-color: ${(props) => {
    if (!props.validate) return props.theme.dark;
    if (!props.isValid) return props.theme.invalidColor;
    return props.theme.borderValid;
  }};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
  flex: 1;
  line-height: 30px;
  font-size: 16px;
`;

const Error = styled.div`
  color: ${(props) => props.theme.invalidTextColor};
  margin-top: 5px;
  padding-left: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  max-width: 400px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  padding-left: 5px;
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.defaultMargin};
`;

// Definitions
type Props = {
  value: string;
  onChange: any;
  label?: string;
  placeholder?: string;
  mandatory?: boolean;
  isValid?: boolean;
  validate?: boolean;
  errorMessage?: string;
  className?: string;
  type?: string;
};

// Component / Hook
export const TextField = (props: Props) => {
  const {
    placeholder = "",
    onChange,
    value,
    label,
    mandatory,
    isValid = false,
    validate = false,
    type = "text",
    className,
    errorMessage = "Bitte überprüfen Sie Ihre Eingaben.",
  } = props;

  const onChangeIntern = (options: any) => {
    const value = options.target.value;
    onChange(value);
  };

  return (
    <Wrapper>
      {label && (
        <Label>
          {label}
          {mandatory && " *"}
        </Label>
      )}
      <TextWrapper className={className}>
        <Text
          type={type}
          placeholder={placeholder}
          onChange={onChangeIntern}
          value={value}
          isValid={isValid}
          validate={validate}
        />
      </TextWrapper>
      {errorMessage && !isValid && validate && <Error>{errorMessage}</Error>}
    </Wrapper>
  );
};
