import * as React from "react";

// Definitions
interface State {}
interface Action {
  step: string;
  payload: object;
  stepAction?: string;
}

// Settings
const LOCAL_STORAGE_KEY = "pacvertise:admin:state";
const initialStateString = localStorage.getItem(LOCAL_STORAGE_KEY);

const defaultState: any = {
  authkey: null,
};

const initialState = initialStateString
  ? JSON.parse(initialStateString)
  : defaultState;

// Context
const AppContext = React.createContext(initialState);

// Main redcuer
const reducer: React.Reducer<State, Action> = (state: any, action: any) => {
  const newState = { ...state, ...action };

  // Save into local storage
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
  return newState;
};

const ContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState,
  );
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, ContextProvider, AppContextConsumer };
