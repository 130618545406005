// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";

// Styles
const Content = styled.div`
  padding: 0 20px 40px 0;
  margin: 0 auto;
  min-height: 400px;
  padding-top: 140px;
`;

// Definitions
type Props = {
  children: ReactChild;
};

// Component / Hook
export const ContentContainer = (props: Props) => {
  return <Content>{props.children}</Content>;
};
