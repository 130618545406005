// Modules
import React from "react";
import styled from "styled-components";

// Images
import logo from "../../images/pacvertise-logo-claim.png";

// Container
import { MainContainer } from "../../containers/Main";

// Styles
const HeaderContainer = styled.header`
  padding: 10px 0 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.light};
  z-index: 2;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  max-width: 350px;
`;

const HeaderMenu = styled.p`
  align-self: center;
  flex: 1;
  text-align: right;
  padding-right: 20px;
`;

const HeaderMenuLink = styled.a`
  margin-left: 10px;
  text-decoration: none;
  color: ${(props) => props.theme.defaultTextColor};
`;

export const Header = () => {
  return (
    <HeaderContainer>
      <MainContainer>
        <HeaderContent>
          <Logo src={logo} />
          <HeaderMenu>
            <HeaderMenuLink href="/">Konfigurator</HeaderMenuLink>
            <HeaderMenuLink href="/">Jobangebote</HeaderMenuLink>
            <HeaderMenuLink href="/">Kontakt</HeaderMenuLink>
          </HeaderMenu>
        </HeaderContent>
      </MainContainer>
    </HeaderContainer>
  );
};
