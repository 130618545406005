// Modules
import React, { useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// Context
import { AppContext } from "./ContextProvider";

// Screens
import { Footer } from "./screens/Footer";
import { Header } from "./screens/Header";
import { Start } from "./screens/Start";
import { Login } from "./screens/Login";

// Components
import { ContentContainer } from "./containers/Content";
import { MainContainer } from "./containers/Main";

export const Router = () => {
  const { state } = useContext(AppContext);

  let content =
    state.authkey !== null ? (
      <Route path="/" component={Start} key={`route_admin`} />
    ) : (
      <Login />
    );

  return (
    <BrowserRouter>
      <Header />
      <ContentContainer>
        <MainContainer>{content}</MainContainer>
      </ContentContainer>
      <Footer />
    </BrowserRouter>
  );
};
