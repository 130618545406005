// Modules
import React, { Fragment } from "react";
import { createGlobalStyle } from "styled-components";

// Modules
import { Router } from "./Router";

interface MyThemeProps {
  theme: any;
}

// Styles
const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    font-family: ${(props: MyThemeProps) => props.theme.defaultFontFamily};
  }

  a {
    color: ${(props: MyThemeProps) => props.theme.primaryColor};
    text-decoration: none;
  }
`;

export const App = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <Router />
    </Fragment>
  );
};
